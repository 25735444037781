import BaseService, {
  Either,
} from 'services/BaseMonadicService';

import {
  BaseResponse,
} from 'types/app';
import {
  AreaWithNestedJsonInfo,
  ISetting,
} from 'types/map';
import {
  PrivilegeLevel,
} from 'types/user';

export interface ILoginCredentials {
  emailAddress: string,
  password: string,
  accessCodeName?: string,
};

export type LoginResponseUser = {
  emailAddress?: string,
  id?: number,
  name?: string,
  nex?: string,
};

export type LoginResponse = {
  accessCodeResult?: {
    message: string,
    isSuccess: boolean,
  },
  area?: AreaWithNestedJsonInfo,
  hasCharacter: boolean,
  jwt?: string,
  message?: string,
  privilegeLevel: PrivilegeLevel,
  request?: {
    emailAddress?: string,
    password?: string,
    accessCodeName?: string,
    gender?: string,
    avatar?: string,
    userName?: string,
  },
  user?: LoginResponseUser,
};

export interface FetchUserResponse {
  area: AreaWithNestedJsonInfo,
  hasCharacter: boolean,
  jwt: string,
  privilegeLevel: PrivilegeLevel,
  setting: ISetting,
  shouldDisplayAuthNag: boolean,
  user: LoginResponseUser,
};

export interface IUserState {
  hasCharacter: boolean,
  isFetching: boolean,
  isTwitterNagScreenOpen: boolean,
  loggedIn: boolean,
  privilegeLevel: PrivilegeLevel | undefined,
  user: {
    createdAt: string | undefined,
    name: string | undefined,
    id: number | undefined,
    hasMessagingEmail: boolean,
  } | undefined,
};

export interface IUserService {
  fetchUser: () => Promise<Either<FetchUserResponse>>,
  facebookLogin: (arg: any) => Promise<Either<LoginResponse>>,
  googleLogin: (arg: { accessCodeName?: string, token: string }) => Promise<Either<LoginResponse>>,
  login: (credentials: ILoginCredentials) => Promise<Either<LoginResponse>>,
  logout: () => Promise<Either<{/* empty response expected */}>>,
  facebookData: (confirmationCode?: string) => Promise<Either<BaseResponse>>,
};

export default class UserService extends BaseService implements IUserService {
  constructor() {
    super();

    this.config = {
      ...this.config,
      method: 'post',
    };
  }

  login = (creds: ILoginCredentials) => {
    const config = {
      method: 'post',
      url: '/login',
      data: {
        accessCodeName: creds.accessCodeName,
        email: creds.emailAddress,
        password: creds.password,
      },
    };

    return this.doRequest<LoginResponse>(config);
  };

  fetchUser = () => {
    const config = {
      url: '/login/user',
      method: 'get',
    };

    return this.doRequest(config);
  };

  facebookLogin = (data: any) => {
    const config = {
      method: 'post',
      url: '/facebook/processsignedrequest',
      data,
    };

    return this.doRequest(config);
  };

  googleLogin = (token: any) => {
    const config = {
      method: 'post',
      url: '/google/callback',
      data: {
        accessCodeName: token?.accessCodeName,
        token: token?.token?.access_token,
      },
    };

    return this.doRequest<LoginResponse>(config);
  };

  logout = () => {
    const config = {
      method: 'post',
      url: '/login/logout',
    };

    return this.doRequest(config);
  };

  facebookData = (confirmationCode?: string) => {
    const config = {
      method: 'get',
      url: `/facebook/dataconfirmation/${confirmationCode}`,
    };

    return this.doRequest(config);
  };
}
