import React, {
  useCallback,
} from 'react';

import classnames from 'classnames';

import getAgentNames from 'components/Agents/getAgentNames';
import Image from 'components/Image';
import Modal from 'components/Modal';

import {
  useAppSelector,
} from 'features/app/store';

import {
  Agent,
  PlotHint,
} from 'types/agents';
import {
  IEnhancement,
  IQuality,
} from 'types/qualities';

interface Props {
  agent?: Agent,
  agents: Agent[],
  header: string,
  hints?: PlotHint[],
  isOpen: boolean,
  onChoose: (quality?: IQuality, category?: string) => void,
  onRequestClose: () => void,
  qualities: IQuality[],
}

type LoanableItem = IQuality & {
  borrowers: Agent[],
  isWorn: boolean,
};

export default function AgentInventoryItemPicker({
  agent,
  agents,
  header,
  hints,
  isOpen,
  onChoose,
  onRequestClose,
  qualities,
}: Props) {
  const category = qualities.map(quality => quality.category).find(c => c);
  const emptySlotImage = useAppSelector(state => state.myself.categories.find(c => c.name.replace(/ /g, '') === category)?.image);
  const wornItemId = useAppSelector(state => state.outfit.slots[category ?? '']?.id);
  const plotHints = hints ?? [];

  const inventoryItems = qualities
    .map(q => ({
      ...q,
      borrowers: agents.filter(a => a.inventory.map(i => i.id).includes(q.id)),
      isWorn: q.id === wornItemId,
    }) as LoanableItem)
    .sort((a, b) => {
      const bestA = getBestHintedEnhancement(a, plotHints);
      const bestB = getBestHintedEnhancement(b, plotHints);

      if (bestA === undefined && bestB === undefined) {
        return a.id - b.id;
      }

      if (bestA === undefined) {
        return 1;
      }

      if (bestB === undefined) {
        return -1;
      }

      const enhSort = bestA.qualityId - bestB.qualityId;

      if (enhSort !== 0) {
        return enhSort;
      }

      return getHintedSortLevel(bestB) - getHintedSortLevel(bestA);
    });

  const handleClick = useCallback((item?: IQuality) => {
    onChoose(item, category);
    onRequestClose();
  }, [
    category,
    onChoose,
    onRequestClose,
  ]);

  if (!agent) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      className='modal-dialog--agent-inventory-picker'
      onRequestClose={onRequestClose}
    >
      <div className='agent-inventory-picker'>
        <h1 className='agent-inventory-picker-header'>
          {header}
        </h1>
        <div className='agent-inventory-picker__scrollbox'>
          <div className='agent-inventory-picker__items'>
            <div className='agent-inventory-picker__item' key={0}>
              <Image
                icon={emptySlotImage}
                alt='clear slot'
                type='small-icon'
                onClick={() => handleClick(undefined)}
                style={{
                  cursor: 'pointer',
                  imageRendering: 'auto',
                }}
                tooltipData={{
                  description: 'Empty this slot.',
                  name: header,
                  smallButtons: [{
                    label: 'Empty Slot',
                    action: () => handleClick(undefined),
                  }],
                }}
              />
            </div>
            {inventoryItems.map(item => (
              <div className='agent-inventory-picker__item' key={item.id}>
                <Image
                  icon={item.image}
                  alt={item.name}
                  defaultCursor={!isItemLoanable(agent, item)}
                  type='small-icon'
                  onClick={isItemLoanable(agent, item) ? (() => handleClick(item)) : (() => {})}
                  style={{
                    imageRendering: 'auto',
                  }}
                  className={classnames(
                    !isAgentBorrowingItem(agent, item) && !isItemLoanable(agent, item) && 'icon--locked',
                    isAgentBorrowingItem(agent, item) && 'agent-inventory-picker__lent-item',
                    !isAgentBorrowingItem(agent, item) && getBestHintedSortLevel(item, plotHints) > 0 && 'agent-inventory-picker__hinted-item',
                  )}
                  tooltipData={{
                    description: item.description,
                    name: [
                      item.name,
                      item.levelDescription ? `— ${item.levelDescription}` : '',
                    ].join(' '),
                    secondaryDescription: '<span class=\'agent-inventory-picker__secondary-description\'>'
                      + getSecondaryDescription(agent, item)
                      + '</span>',
                    enhancements: item.enhancements,
                    smallButtons: isItemLoanable(agent, item)
                      ? [{
                          label: 'Equip',
                          action: () => handleClick(item),
                        }]
                      : [],
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}

AgentInventoryItemPicker.displayName = 'AgentInventoryItemPicker';

function getBestHintedSortLevel(item: LoanableItem, hints: PlotHint[]) {
  return Math.max(
    ...hints.map(hint => getHintedSortLevel(item.enhancements?.find(enh => enh.qualityId === hint.qualityId)))
  );
}

function getHintedSortLevel(enhancement?: IEnhancement) {
  return (enhancement?.level ?? 0) * ((enhancement?.category ?? '') === 'Skills' ? 10 : 1);
}

function getBestHintedEnhancement(item: LoanableItem, hints: PlotHint[]) {
  return hints
    .map(hint => item.enhancements?.find(enh => enh.qualityId === hint.qualityId))
    .sort((a, b) => {
      const baseSort = getHintedSortLevel(b) - getHintedSortLevel(a);

      return (baseSort === 0) ? (a?.qualityId ?? 0) - (b?.qualityId ?? 0) : baseSort;
    })
    .find(() => true);
}

function isAgentBorrowingItem(agent: Agent, item: LoanableItem) {
  return item.borrowers.map(b => b.id).includes(agent.id);
}

function isItemLoanable(agent: Agent, item: LoanableItem) {
  if (isAgentBorrowingItem(agent, item)) {
    // this agent already has this item
    return false;
  }

  return item.borrowers.filter(b => b.plot).length < item.level;
}

function getSecondaryDescription(agent: Agent, item: LoanableItem) {
  if (isAgentBorrowingItem(agent, item)) {
    // this agent already has this item
    return `${agent.name} has already equipped this item.`;
  }

  if (item.level === 0) {
    // should be unreachable: item is in your inventory, but also you don't have any of it?
    return 'You do not own this item.';
  }

  // these agents get to keep their items because they're away on a plot
  const workingAgents = item.borrowers.filter(b => b.plot);

  if (workingAgents.length >= item.level) {
    // all copies are off-limits
    return `You cannot loan out this item because it is currently equipped by ${getAgentNames(workingAgents)}.`;
  }

  const unclaimedCount = item.level - item.borrowers.length - (item.isWorn ? 1 : 0);

  // we can loan out an item without affecting anyone else's inventory
  if (unclaimedCount > 0) {
    const are = unclaimedCount === 1 ? 'is' : 'are';
    const unclaimedPrefix = `There ${are} ${unclaimedCount} of this item you can loan out`;

    if (item.borrowers.length === 0) {
      if (item.isWorn) {
        // you're wearing this item, but have more to loan out
        return `${unclaimedPrefix}, in addition to the one you have equipped.`;
      }

      // item is not worn by anyone
      return `${unclaimedPrefix}.`;
    }

    if (item.isWorn) {
      // you're wearing this, 1 or more of your agents are wearing it, and there are more to go around
      return unclaimedPrefix + ', in addition to the ones equipped by '
        + item.borrowers.map(b => b.name).join(', ')
        + (item.borrowers.length > 1 ? ',' : '')
        + ' and yourself.';
    }

    // you're not wearing this, but 1 or more of your agents are; there are more to go around
    const one = item.borrowers.length === 1 ? 'one' : 'ones';

    return `${unclaimedPrefix}, in addition to the ${one} equipped by ${getAgentNames(item.borrowers)}.`;
  }

  // loaning out this item means taking it away from someone else
  if (item.borrowers.length === 0) {
    if (item.isWorn) {
      // you're wearing the only instance of this item
      return 'This item is currently equipped by you.';
    }

    // should be unreachable: item is in your inventory, but you don't have any of it
    return 'You do not own this item.';
  }

  const freeAgents = item.borrowers.filter(b => !b.plot);

  if (freeAgents.length === 0) {
    if (item.isWorn) {
      // agents have taken your items out on a plot, but you're also wearing one
      return 'This item is currently equipped by you, as well as ' + getAgentNames(item.borrowers) + '.';
    }

    // should be unreachable: previous checks eliminate this possibility
    return 'You do not have this item.';
  }

  const freeAgent = freeAgents[0];
  const resortedAgents = [
    freeAgent,
    ...item.borrowers.filter(b => b.id !== freeAgent.id),
  ];

  if (item.isWorn) {
    // you're wearing this, and your agents are wearing all of the other copies
    return `This item is currently equipped by ${getAgentNames(resortedAgents)}, as well as yourself.`;
  }

  // you've loaned out all copies of this item to your agents
  return `This item is currently equipped by ${getAgentNames(resortedAgents)}.`;
}
